// src/components/FormSection5_TargetJobInformation.js

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Snackbar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  MenuItem,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { SubsectionHeader, FormField, DateHelperText } from "./FormComponents";
import WorkIcon from "@material-ui/icons/Work";
import BusinessIcon from "@material-ui/icons/Business";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import GenerateApplicationMaterialsButton from "./GenerateApplicationMaterialsButton";
import GeneratedContentDisplay from "./GeneratedContentDisplay";
import ApplicationMaterialSkeleton from "./ApplicationMaterialSkeleton";

// Firestore imports
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../util/firebase";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

const MAX_JOB_ENTRIES = 10;
const MAX_TOOLKIT_SELECTIONS = 20;

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formContent: {
    position: "relative",
  },
  formContainer: {
    padding: theme.spacing(3),
  },
  entryContainer: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  checkboxGrid: {
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(1),
  },
  generateButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  generatedContentContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  jobTitleHeader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(-4),
    fontWeight: 600,
  },
  materialContainer: {
    marginBottom: theme.spacing(4),
  },
  targetJobHeader: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  // Style for the Alert to align with theme
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  // Custom styles for application deadline to prevent label overlap
  applicationDeadlineField: {
    "& .MuiInputBase-root": {
      // paddingTop: theme.spacing(1.5),
      // paddingBottom: theme.spacing(1.5),
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      // Optional: Customize the calendar icon if desired
      color: theme.palette.primary.main,
    },
  },
}));

const materialTypeMap = {
  resume: "Resume",
  coverLetter: "Cover Letter",
  references: "References",
  recruiterMessage: "Message to Recruiter",
  linkedinOptimization: "LinkedIn Profile Optimization",
  interviewPrep: "Interview Preparation",
  networkingEmails: "Networking Email Templates",
  careerDevelopmentPlan: "Career Development Plan",
  followUpEmails: "Job Application Follow-up Emails",
  salaryNegotiation: "Salary Negotiation Scripts",
  professionalBio: "Professional Bio Creation",
  personalBranding: "Personal Branding Tips",
  skillGapAnalysis: "Skill Gap Analysis",
  companyResearch: "Company Research Reports",
};

// Static form fields configuration
const formSections = [
  {
    title: "Job Details",
    fields: [
      {
        name: "jobTitle",
        label: "Job Title",
        required: true,
        icon: WorkIcon,
        maxLength: 100,
      },
      {
        name: "companyName",
        label: "Company Name",
        required: true,
        icon: BusinessIcon,
        maxLength: 100,
      },
      {
        name: "jobLocation",
        label: "Job Location",
        maxLength: 100,
      },
      {
        name: "jobType",
        label: "Job Type",
        required: true,
        select: true,
        options: [
          { value: "Full-time", label: "Full-time" },
          { value: "Part-time", label: "Part-time" },
          { value: "Contract", label: "Contract" },
          { value: "Internship", label: "Internship" },
          { value: "Temporary", label: "Temporary" },
        ],
      },
    ],
  },
  {
    title: "Salary and Deadline",
    fields: [
      {
        name: "salary",
        label: "Salary",
        type: "text",
      },
      {
        name: "applicationDeadline",
        label: "Application Deadline",
        type: "date",
        helperText: "Enter the deadline for submitting your application",
        InputLabelProps: { shrink: true }, // Prevent label overlap
      },
    ],
  },
  {
    title: "Job Description",
    fields: [
      {
        name: "jobDescription",
        label: "Job Description",
        required: true,
        multiline: true,
        rows: 8,
        fullWidth: true,
        maxLength: 2000,
      },
    ],
  },
];

// Static generation options configuration
const generationOptions = [
  {
    name: "resume",
    label: "Resume",
    tooltip: "Generate a tailored resume for this job",
  },
  {
    name: "coverLetter",
    label: "Cover Letter",
    tooltip: "Create a customized cover letter",
  },
  {
    name: "references",
    label: "References",
    tooltip: "Prepare a list of professional references",
  },
  {
    name: "recruiterMessage",
    label: "Message to Recruiter",
    tooltip: "Generate a personalized message for LinkedIn recruiters",
  },
  {
    name: "linkedinOptimization",
    label: "LinkedIn Profile Optimization",
    tooltip: "Get suggestions to improve your LinkedIn profile",
  },
  {
    name: "interviewPrep",
    label: "Interview Preparation",
    tooltip: "Prepare for job interviews with tailored questions and answers",
  },
  {
    name: "networkingEmails",
    label: "Networking Email Templates",
    tooltip: "Create templates for networking emails",
  },
  {
    name: "careerDevelopmentPlan",
    label: "Career Development Plan",
    tooltip: "Get a personalized career development plan",
  },
  {
    name: "followUpEmails",
    label: "Job Application Follow-up Emails",
    tooltip: "Generate follow-up emails for your job application",
  },
  {
    name: "salaryNegotiation",
    label: "Salary Negotiation Scripts",
    tooltip: "Receive scripts for salary negotiations",
  },
  {
    name: "professionalBio",
    label: "Professional Bio Creation",
    tooltip: "Create a professional bio for various platforms",
  },
  {
    name: "personalBranding",
    label: "Personal Branding Tips",
    tooltip: "Get tips for building your personal brand",
  },
  {
    name: "skillGapAnalysis",
    label: "Skill Gap Analysis",
    tooltip: "Identify and address skill gaps for this job",
  },
  {
    name: "companyResearch",
    label: "Company Research Reports",
    tooltip: "Get detailed reports on the target company",
  },
];

const initialTargetJobInfo = {
  jobTitle: "",
  companyName: "",
  jobLocation: "",
  jobType: "",
  salary: "",
  applicationDeadline: "",
  jobDescription: "",
  generationOptions: generationOptions.reduce((acc, option) => {
    acc[option.name] = false;
    return acc;
  }, {}),
};

const TargetJobEntry = React.memo(
  ({
    index,
    jobInfo,
    handleFieldChange,
    handleOptionChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
  }) => {
    const classes = useStyles();

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader title={`Target Job ${index + 1}`} />
        {formSections.map((section) => (
          <React.Fragment key={section.title}>
            <SubsectionHeader title={section.title} />
            <Grid container spacing={3}>
              {section.fields.map((field) => (
                <Grid
                  item
                  xs={12}
                  sm={field.fullWidth ? 12 : 6}
                  key={field.name}
                >
                  <FormField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    value={jobInfo[field.name] || ""}
                    onChange={(name, value) =>
                      handleFieldChange(index, name, value)
                    }
                    error={errors[field.name]}
                    tooltip={`Enter the ${field.label.toLowerCase()}`}
                    aria-label={field.label}
                    placeholder={
                      field.type === "date"
                        ? "mm/dd/yyyy"
                        : `Enter ${field.label.toLowerCase()}`
                    }
                    maxLength={field.maxLength}
                    type={field.type}
                    multiline={field.multiline}
                    rows={field.rows}
                    fullWidth
                    select={field.select}
                    options={field.options}
                    icon={field.icon ? <field.icon /> : null}
                    InputLabelProps={
                      field.type === "date" ? field.InputLabelProps : undefined
                    }
                    className={
                      field.name === "applicationDeadline"
                        ? classes.applicationDeadlineField
                        : undefined
                    }
                  >
                    {field.select &&
                      field.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </FormField>
                  {field.type === "date" && (
                    <DateHelperText text={field.helperText} />
                  )}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
        <SubsectionHeader title="Job Application Toolkit:" />
        <Grid container spacing={2} className={classes.checkboxGrid}>
          {generationOptions.map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.name}>
              <Tooltip title={option.tooltip} placement="top">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobInfo.generationOptions[option.name]}
                      onChange={(e) =>
                        handleOptionChange(index, option.name, e.target.checked)
                      }
                      name={option.name}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      className={classes.checkboxLabel}
                    >
                      {option.label}
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this target job">
            <IconButton
              onClick={() => handleRemoveEntry(index)}
              color="secondary"
              aria-label="Remove target job"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another target job">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add target job"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

export const TargetJobInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [showGeneratedContent, setShowGeneratedContent] = useState(false);
  const [generatedContent, setGeneratedContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [loading, setLoading] = useState(false);
  const contentRef = useRef(null);

  const auth = useAuth(); // Get the authenticated user

  // Ref to store previous form data
  const prevFormDataRef = useRef();

  // Debounced auto-save function to prevent excessive writes
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    []
  );

  // Save data to localStorage and Firestore
  const saveData = async (data) => {
    // Save to localStorage
    localStorage.setItem("targetJobInformation", JSON.stringify(data));

    // If user is authenticated, save to Firestore
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      try {
        // Use setDoc with merge: true
        await setDoc(
          userDocRef,
          { targetJobInformation: data },
          { merge: true }
        );
      } catch (error) {
        console.error("Error saving data:", error);
        throw error; // Re-throw to handle in debouncedAutoSave
      }
    }
  };

  // Load data from localStorage or Firestore on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("targetJobInformation");
        if (localData) {
          const parsedData = JSON.parse(localData);
          updateFormData("targetJobInformation", parsedData);
        } else if (auth.user) {
          // Fetch from Firestore
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data().targetJobInformation;
            if (data) {
              updateFormData("targetJobInformation", data);
              // Optionally, save to localStorage
              localStorage.setItem(
                "targetJobInformation",
                JSON.stringify(data)
              );
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  // Auto-save whenever targetJobInformation data changes
  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.targetJobInformation;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.targetJobInformation, debouncedAutoSave, loading]);

  // Count total toolkit selections across all jobs
  const countToolkitSelections = () => {
    return formData.targetJobInformation.reduce((total, job) => {
      return (
        total + Object.values(job.generationOptions).filter(Boolean).length
      );
    }, 0);
  };

  // Handle changes to form fields
  const handleFieldChange = (index, name, value) => {
    const updatedJob = {
      ...formData.targetJobInformation[index],
      [name]: value,
    };
    const updatedJobs = [...formData.targetJobInformation];
    updatedJobs[index] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);
  };

  // Handle changes to generation options
  const handleOptionChange = (index, optionName, checked) => {
    if (checked && countToolkitSelections() >= MAX_TOOLKIT_SELECTIONS) {
      setAlertMessage(
        `You can only select up to ${MAX_TOOLKIT_SELECTIONS} toolkit options in total.`
      );
      return;
    }
    const updatedJob = {
      ...formData.targetJobInformation[index],
      generationOptions: {
        ...formData.targetJobInformation[index].generationOptions,
        [optionName]: checked,
      },
    };
    const updatedJobs = [...formData.targetJobInformation];
    updatedJobs[index] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);
  };

  // Add a new target job entry
  const handleAddEntry = () => {
    if (formData.targetJobInformation.length >= MAX_JOB_ENTRIES) {
      setAlertMessage(`You can only add up to ${MAX_JOB_ENTRIES} target jobs.`);
      return;
    }
    updateFormData("targetJobInformation", [
      ...formData.targetJobInformation,
      { ...initialTargetJobInfo },
    ]);
  };

  // Remove a target job entry
  const handleRemoveEntry = (index) => {
    const updatedJobs = formData.targetJobInformation.filter(
      (_, i) => i !== index
    );
    updateFormData("targetJobInformation", updatedJobs);
  };

  // Handle content generation
  const handleContentGenerated = (content) => {
    setGeneratedContent(content);
    setShowGeneratedContent(true);
    setIsLoading(false);
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  // Render generated content
  const renderGeneratedContent = () => {
    return formData.targetJobInformation.map((job, jobIndex) => (
      <Box key={jobIndex} className={classes.materialContainer}>
        {isLoading ? (
          Object.entries(job.generationOptions)
            .filter(([_, value]) => value)
            .map(([key]) => (
              <ApplicationMaterialSkeleton
                key={key}
                title={materialTypeMap[key] || key}
              />
            ))
        ) : (
          <GeneratedContentDisplay
            isVisible={showGeneratedContent}
            generatedContent={generatedContent[jobIndex]}
            jobDetails={{
              jobTitle: job.jobTitle || "Job",
              companyName: job.companyName || "Company",
            }}
            updateGeneratedContent={(key, newValue) => {
              const updatedContent = [...generatedContent];
              updatedContent[jobIndex] = {
                ...updatedContent[jobIndex],
                [key]: newValue,
              };
              setGeneratedContent(updatedContent);
            }}
            isLoading={isLoading}
            generationOptions={job.generationOptions}
          />
        )}
        {jobIndex < formData.targetJobInformation.length - 1 && <Divider />}
      </Box>
    ));
  };

  const targetJobInformation = Array.isArray(formData.targetJobInformation)
    ? formData.targetJobInformation
    : [{ ...initialTargetJobInfo }];

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <div className={classes.formContainer}>
            <Typography variant="h6" className={classes.targetJobHeader}>
              Target Job Information:
            </Typography>
            {targetJobInformation.map((jobInfo, index) => (
              <TargetJobEntry
                key={index}
                index={index}
                jobInfo={jobInfo}
                handleFieldChange={handleFieldChange}
                handleOptionChange={handleOptionChange}
                handleRemoveEntry={handleRemoveEntry}
                handleAddEntry={handleAddEntry}
                errors={errors[`targetJobInformation[${index}]`] || {}}
              />
            ))}
          </div>
        )}
        <Divider />
        <div className={classes.generateButtonContainer}>
          <GenerateApplicationMaterialsButton
            onContentGenerated={handleContentGenerated}
            onGenerateStart={() => setIsLoading(true)}
          />
        </div>
      </div>
      {(isLoading || showGeneratedContent) && (
        <div className={classes.generatedContentContainer} ref={contentRef}>
          {renderGeneratedContent()}
        </div>
      )}
      {/* Alert Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert onClose={() => setAlertMessage(null)} severity="warning">
          {alertMessage}
        </Alert>
      </Snackbar>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert} // Apply custom styling
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default TargetJobInformation;
