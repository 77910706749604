// src/util/firebase.js
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, // Ensure this is set in your .env file
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, // Ensure this is set in your .env file
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, // Ensure this is set in your .env file
  // Add other configuration properties if needed
};

// Initialize Firebase app
let app;
if (getApps().length === 0) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

// Initialize Firestore
const db = getFirestore(app);

// Export both the Firebase app and Firestore database
export { app as firebaseApp, db };
