// src/components/FormSection1_PersonalInformation.js

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { FormContext } from "./FormContext";
import {
  Grid,
  Paper,
  Typography,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  LinkedIn as LinkedInIcon,
  Language as LanguageIcon,
  Twitter as TwitterIcon,
  GitHub as GitHubIcon,
  Person as PersonIcon,
  Home as HomeIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Work as WorkIcon,
} from "@material-ui/icons";
import { FormField } from "./FormComponents";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore"; // Import setDoc

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import { validateField } from "./validation"; // Ensure this function is properly defined
import debounce from "lodash.debounce"; // Ensure lodash.debounce is installed via npm
import isEqual from "lodash/isEqual"; // Ensure lodash is installed via npm

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  formPaper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
  },
  personalInformation: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  formSection: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  subsectionHeader: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  autoSaveMessage: {
    display: "flex",
    alignItems: "center",
  },
  loadingIndicator: {
    marginLeft: theme.spacing(2),
  },
  // New style for the Alert to align with theme
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

// SubsectionHeader Component
const SubsectionHeader = React.memo(({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.subsectionHeader}>
      {title}
    </Typography>
  );
});

// PersonalInformation Component
export const PersonalInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [autoSaveStatus, setAutoSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [loading, setLoading] = useState(false);

  const auth = useAuth(); // Get the authenticated user

  // State to track user interaction
  const [hasInteracted, setHasInteracted] = useState(false);

  // Ref to store previous form data
  const prevFormDataRef = useRef(formData.personalInformation);

  // Handle field changes with validation
  const handleChange = (name, value) => {
    // Mark that the user has interacted with the form
    if (!hasInteracted) {
      setHasInteracted(true);
    }

    // Update form data
    updateFormData("personalInformation", {
      ...formData.personalInformation,
      [name]: value,
    });

    // Validate field
    const fieldError = validateField(name, value, formData);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };

  // Debounced auto-save function to prevent excessive writes
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    []
  );

  // Save data to localStorage and Firestore
  const saveData = async (data) => {
    // Save to localStorage
    localStorage.setItem("personalInformation", JSON.stringify(data));

    // If user is authenticated, save to Firestore
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      try {
        // Use setDoc with merge: true
        await setDoc(
          userDocRef,
          { personalInformation: data },
          { merge: true }
        );
        // console.log("Data successfully saved!");
      } catch (error) {
        console.error("Error saving data:", error);
        throw error; // Re-throw to handle in debouncedAutoSave
      }
    }
  };

  // Load data from localStorage or Firestore on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("personalInformation");
        if (localData) {
          const parsedData = JSON.parse(localData);
          updateFormData("personalInformation", parsedData);
          // console.log("Loaded Personal Information from localStorage.");
        } else if (auth.user) {
          // Fetch from Firestore
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data().personalInformation;
            if (data) {
              updateFormData("personalInformation", data);
              // console.log("Loaded Personal Information from Firestore.");
              // Optionally, save to localStorage
              localStorage.setItem("personalInformation", JSON.stringify(data));
            } else {
              // console.log("No Personal Information found in Firestore.");
            }
          } else {
            // console.log("No user document found in Firestore.");
          }
        } else {
          // console.log("No local data and user is not authenticated.");
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, updateFormData]);

  // Auto-save whenever personalInformation changes and user has interacted
  useEffect(() => {
    if (!loading && hasInteracted) {
      const currentFormData = formData.personalInformation;
      const previousFormData = prevFormDataRef.current;

      if (!isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
        prevFormDataRef.current = currentFormData;
      }
    }
  }, [formData.personalInformation, debouncedAutoSave, loading, hasInteracted]);

  // Define form fields structure
  const formFields = [
    {
      section: "Name",
      fields: [
        {
          name: "firstName",
          label: "First Name",
          required: true,
          icon: PersonIcon,
        },
        {
          name: "lastName",
          label: "Last Name",
          required: true,
        },
      ],
    },
    {
      section: "Address",
      fields: [
        {
          name: "streetAddress",
          label: "Street Address",
          required: true,
          icon: HomeIcon,
          fullWidth: true,
        },
        { name: "city", label: "City", required: true },
        { name: "state", label: "State/Province", required: true },
        { name: "zipCode", label: "Zip/Postal Code", required: true },
        { name: "country", label: "Country", required: true },
      ],
    },
    {
      section: "Contact Information",
      fields: [
        {
          name: "phone",
          label: "Phone",
          required: true,
          type: "tel",
          icon: PhoneIcon,
        },
        {
          name: "email",
          label: "Email Address",
          required: true,
          type: "email",
          icon: EmailIcon,
        },
      ],
    },
    {
      section: "Online Presence",
      fields: [
        {
          name: "linkedinUrl",
          label: "LinkedIn Profile URL",
          icon: LinkedInIcon,
        },
        {
          name: "websiteUrl",
          label: "Website/Portfolio URL",
          icon: LanguageIcon,
        },
        {
          name: "twitterHandle",
          label: "X (Twitter) Handle",
          icon: TwitterIcon,
        },
        {
          name: "githubUsername",
          label: "GitHub Username",
          icon: GitHubIcon,
        },
        {
          name: "otherSocialProfile",
          label: "Other Social Profile",
          fullWidth: true,
        },
      ],
    },
    {
      section: "Professional Information",
      fields: [
        {
          name: "professionalTitle",
          label: "Professional Title",
          icon: WorkIcon,
          fullWidth: true,
        },
        {
          name: "summary",
          label: "Summary/Objective Statement",
          multiline: true,
          rows: 4,
          fullWidth: true,
          maxLength: 800,
        },
      ],
    },
  ];

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <Typography
        variant="h6"
        className={classes.personalInformation}
        align="center"
      >
        Personal Information:
      </Typography>
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <form noValidate autoComplete="off">
            {formFields.map(({ section, fields }) => (
              <React.Fragment key={section}>
                <SubsectionHeader title={section} />
                <Grid container spacing={3} className={classes.formSection}>
                  {fields.map((field) => (
                    <FormField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      type={field.type}
                      value={formData.personalInformation[field.name] || ""}
                      onChange={handleChange}
                      error={errors[field.name]}
                      tooltip={`Enter your ${field.label.toLowerCase()}`}
                      icon={field.icon ? <field.icon /> : null}
                      fullWidth={field.fullWidth}
                      multiline={field.multiline}
                      rows={field.rows}
                      maxLength={field.maxLength}
                      aria-label={field.label}
                    />
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          </form>
        )}
      </div>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert} // Apply custom styling
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(PersonalInformation);
