// src/pages/ProPage.jsx

import React from "react";
import Meta from "../components/Meta";
import JobDetailsForm from "../components/JobDetailsForm";
import { requireAuth } from "../util/auth";
import { FormProvider } from "../components/FormContext";
import { Container, Typography, Box, makeStyles } from "@material-ui/core";

// Define custom styles for the banner
const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: theme.palette.primary.main, // Use your primary theme color
    color: theme.palette.primary.contrastText, // Ensure text contrasts with background
    padding: theme.spacing(4, 0), // Reduced vertical padding
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3), // Reduced bottom margin
  },
  subtitle: {
    marginTop: theme.spacing(1), // Reduced top margin for subtitle
    maxWidth: "600px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const ProPage = () => {
  const classes = useStyles();

  return (
    <>
      <Meta title="Pro" />
      <FormProvider>
        <Container maxWidth="md">
          {/* Themed Banner */}
          <Box className={classes.banner}>
            <Typography variant="h4" component="h1" gutterBottom>
              Professional Application Builder
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Enhance your applications quickly with AI-powered resume and cover
              letter customizations.
            </Typography>
          </Box>

          {/* Job Details Form */}
          <JobDetailsForm />
        </Container>
      </FormProvider>
    </>
  );
};

// Export the component wrapped with authentication
export default requireAuth(ProPage);
