// src/components/UploadResumeButton.js
import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
  Typography,
  Modal,
  LinearProgress,
  Paper,
  Fade,
  Backdrop,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert"; // Correct import
import { makeStyles } from "@material-ui/core/styles";
import { AttachFile, Work, School, Person, Stars } from "@material-ui/icons";
import { FormContext } from "./FormContext";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end", // Pushes content to the far right
    alignItems: "center",
    flexDirection: "column",
    width: "100%", // Ensures the container takes full width for right alignment
    padding: theme.spacing(2), // Optional: Adds some padding around
  },
  input: {
    display: "none",
  },
  iconButton: {
    padding: theme.spacing(2), // Increased padding for a bigger button
    backgroundColor: theme.palette.primary.main, // Solid color instead of gradient
    color: "#fff",
    transition: "background 0.3s ease, transform 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      transform: "scale(1.05)",
    },
    boxShadow: theme.shadows[4], // Slightly deeper shadow for prominence
    borderRadius: "50%", // Makes the button circular
    width: 70, // Increased size
    height: 70, // Increased size
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    maxWidth: 300,
    fontSize: "0.875rem",
    backgroundColor: theme.palette.grey[800],
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  processingMessage: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    textAlign: "center",
    boxShadow: theme.shadows[5],
    width: "90vw",
    maxWidth: "400px",
    transition: "all 0.3s ease",
  },
  progressBar: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[300],
  },
  progressIndicator: {
    height: "100%",
    borderRadius: 4,
    background: theme.palette.primary.main, // Solid color for progress
    transition: "width 0.4s ease",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(3),
  },
  icon: {
    fontSize: 30,
    color: theme.palette.grey[400],
    transition: "color 0.3s ease, transform 0.3s ease",
  },
  activeIcon: {
    color: theme.palette.primary.main,
    transform: "scale(1.1)",
  },
  quickFillText: {
    fontSize: "0.75rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "2px 8px",
    borderRadius: 12,
    boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
  },
  alert: {
    marginTop: theme.spacing(2),
    width: "100%",
    maxWidth: 400, // Align with modal width
  },
}));

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const UploadResumeButton = () => {
  const classes = useStyles();
  const { populateFormData } = useContext(FormContext);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [processingMessage, setProcessingMessage] = useState("");
  const [activeIcon, setActiveIcon] = useState(0);

  const resetState = () => {
    setUploading(false);
    setUploadError(null);
    setProgress(0);
    setProcessingMessage("");
    setActiveIcon(0);
  };

  useEffect(() => {
    let timer;
    if (uploading) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 350);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploading]);

  useEffect(() => {
    if (progress < 25) {
      setProcessingMessage("Extracting personal details...");
      setActiveIcon(0);
    } else if (progress < 50) {
      setProcessingMessage("Retrieving educational background...");
      setActiveIcon(1);
    } else if (progress < 75) {
      setProcessingMessage("Analyzing work experience...");
      setActiveIcon(2);
    } else if (progress < 100) {
      setProcessingMessage("Finalizing additional information...");
      setActiveIcon(3);
    } else {
      setProcessingMessage("Completed!");
      setActiveIcon(4);
    }
  }, [progress]);

  const handleFileUpload = useCallback(
    async (file) => {
      if (!file) return;

      if (file.size > MAX_FILE_SIZE) {
        setUploadError(
          "File size exceeds the 5MB limit. Please select a smaller file."
        );
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setUploadError(
          "Invalid file type. Please upload a PDF, DOC, or DOCX file."
        );
        return;
      }

      setUploading(true);
      setUploadError(null);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 35000); // 35 seconds timeout

        const response = await fetch(
          "https://us-central1-resumerevivalapp.cloudfunctions.net/convert_resume_to_json",
          {
            method: "POST",
            body: formData,
            signal: controller.signal,
          }
        );

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        populateFormData(result);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadError(
          error.name === "AbortError"
            ? "Request timed out. Please try again."
            : "Failed to upload and process the file. Please try again."
        );
      } finally {
        resetState();
      }
    },
    [populateFormData]
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resetState();
  };

  const tooltipContent = (
    <React.Fragment>
      <Typography variant="body2">
        Upload your existing resume (PDF, DOC, or DOCX, max 5MB) to quickly fill
        out the form.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 8 }}>
        Note: AI parsing may occasionally result in inaccuracies. Please review
        and edit as needed.
      </Typography>
    </React.Fragment>
  );

  return (
    <Box className={classes.container}>
      <input
        accept=".pdf,.doc,.docx"
        className={classes.input}
        id="icon-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="icon-button-file">
        <Tooltip title={tooltipContent} classes={{ tooltip: classes.tooltip }}>
          <IconButton
            color="primary"
            aria-label="upload resume"
            component="span"
            className={classes.iconButton}
            disabled={uploading}
          >
            {uploading ? (
              <CircularProgress size={30} style={{ color: "#fff" }} />
            ) : (
              <AttachFile style={{ fontSize: 30 }} />
            )}
          </IconButton>
        </Tooltip>
      </label>
      <Typography className={classes.quickFillText}>Quick Fill</Typography>
      <Modal
        open={uploading}
        className={classes.modal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEscapeKeyDown
      >
        <Fade in={uploading}>
          <Paper className={classes.modalContent} elevation={6}>
            <CircularProgress size={60} />
            <Typography variant="h6" className={classes.processingMessage}>
              {processingMessage}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              className={classes.progressBar}
              style={{ backgroundColor: "#e0e0e0" }}
            />
            <div className={classes.iconContainer}>
              <Person
                className={`${classes.icon} ${
                  activeIcon === 0 ? classes.activeIcon : ""
                }`}
              />
              <School
                className={`${classes.icon} ${
                  activeIcon === 1 ? classes.activeIcon : ""
                }`}
              />
              <Work
                className={`${classes.icon} ${
                  activeIcon === 2 ? classes.activeIcon : ""
                }`}
              />
              <Stars
                className={`${classes.icon} ${
                  activeIcon === 3 ? classes.activeIcon : ""
                }`}
              />
            </div>
          </Paper>
        </Fade>
      </Modal>
      {uploadError && (
        <Alert severity="error" className={classes.alert}>
          {uploadError}
        </Alert>
      )}
    </Box>
  );
};

export default UploadResumeButton;
