// FormContext.js
import React, { createContext, useState, useCallback, useEffect } from "react";
import { validateField, validateForm } from "./validation";

export const FormContext = createContext();

const initialEducationEntry = {
  educationType: "College",
  institutionName: "",
  city: "",
  stateProvince: "",
  country: "",
  degree: "", // options: Associate, Bachelor, Master, Doctorate
  fieldOfStudy: "",
  minor: "",
  startDate: "",
  endDate: "",
  currentlyEnrolled: false,
  gpa: "",
  honors: "",
  awards: "",
  activities: "",
  societies: "",
  leadershipRoles: "",
  certifications: "",
  relevantCoursework: "",
};

const initialWorkExperienceEntry = {
  jobTitle: "",
  companyName: "",
  location: "",
  startDate: "",
  endDate: "",
  currentlyEmployed: false,
  jobDescriptionAndAchievements: "",
  supervisorName: "",
  supervisorContact: "",
  reasonForLeaving: "",
  skillsUtilized: "",
  technologiesUsed: "",
};

const initialTargetJobEntry = {
  jobTitle: "",
  companyName: "",
  jobDescription: "",
  jobLocation: "",
  jobType: "",
  salary: "",
  applicationDeadline: "",
  generationOptions: {
    resume: true,
    coverLetter: true,
    references: false,
    recruiterMessage: false,
    linkedinOptimization: false,
    interviewPrep: false,
    networkingEmails: false,
    careerDevelopmentPlan: false,
    followUpEmails: false,
    salaryNegotiation: false,
    professionalBio: false,
    personalBranding: false,
    skillGapAnalysis: false,
    companyResearch: false,
  },
};

const initialQualificationEntry = {
  qualificationType: "",
  details: {
    projectTitle: "",
    role: "",
    institution: "",
    startDate: "",
    endDate: "",
    description: "",
    outcomes: "",
  },
};

const initialFormData = {
  personalInformation: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    linkedinUrl: "",
    websiteUrl: "",
    twitterHandle: "",
    githubUsername: "",
    otherSocialProfile: "",
    professionalTitle: "",
    summary: "",
    profilePhoto: null,
  },
  education: [initialEducationEntry],
  workExperience: [initialWorkExperienceEntry],
  targetJobInformation: [initialTargetJobEntry],
  additionalQualifications: [],
  noWorkExperience: false,
};

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem("formData");
    const initialData = savedData ? JSON.parse(savedData) : initialFormData;
    return initialData;
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const validateFieldAndUpdateErrors = useCallback(
    (section, field, value) => {
      const error = validateField(field, value, formData);
      setErrors((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: error,
        },
      }));
      return error;
    },
    [formData]
  );

  const updateFormData = useCallback((section, data) => {
    setFormData((prev) => {
      const newData = typeof data === "function" ? data(prev[section]) : data;
      return {
        ...prev,
        [section]: newData,
      };
    });
  }, []);

  const populateFormData = useCallback((payload) => {
    setFormData((prev) => {
      const newFormData = { ...prev };

      for (const section in payload) {
        if (newFormData.hasOwnProperty(section)) {
          if (section === "workExperience") {
            newFormData[section] = payload[section].map((job) => ({
              ...job,
              jobDescriptionAndAchievements: Array.isArray(
                job.jobDescriptionAndAchievements
              )
                ? job.jobDescriptionAndAchievements.join("\n")
                : job.jobDescriptionAndAchievements,
            }));
          } else {
            newFormData[section] = payload[section];
          }
        }
      }

      return newFormData;
    });
  }, []);

  const addEducationEntry = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      education: [...prev.education, initialEducationEntry],
    }));
  }, []);

  const updateEducationEntry = useCallback(
    (index, field, value) => {
      setFormData((prev) => {
        const updatedEducation = [...prev.education];
        updatedEducation[index] = {
          ...updatedEducation[index],
          [field]: value,
        };
        validateFieldAndUpdateErrors(`education[${index}]`, field, value);
        return { ...prev, education: updatedEducation };
      });
    },
    [validateFieldAndUpdateErrors]
  );

  const removeEducationEntry = useCallback((index) => {
    setFormData((prev) => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index),
    }));
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[`education[${index}]`];
      return newErrors;
    });
  }, []);

  const setEducation = useCallback((newEducation) => {
    setFormData((prev) => ({
      ...prev,
      education: Array.isArray(newEducation)
        ? newEducation
        : [initialEducationEntry],
    }));
  }, []);

  const addWorkExperienceEntry = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      workExperience: [...prev.workExperience, initialWorkExperienceEntry],
    }));
  }, []);

  const updateWorkExperienceEntry = useCallback(
    (index, field, value) => {
      setFormData((prev) => {
        const updatedWorkExperience = [...prev.workExperience];
        updatedWorkExperience[index] = {
          ...updatedWorkExperience[index],
          [field]: value,
        };
        validateFieldAndUpdateErrors(`workExperience[${index}]`, field, value);
        return { ...prev, workExperience: updatedWorkExperience };
      });
    },
    [validateFieldAndUpdateErrors]
  );

  const removeWorkExperienceEntry = useCallback((index) => {
    setFormData((prev) => ({
      ...prev,
      workExperience: prev.workExperience.filter((_, i) => i !== index),
    }));
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[`workExperience[${index}]`];
      return newErrors;
    });
  }, []);

  const setWorkExperience = useCallback((newWorkExperience) => {
    setFormData((prev) => ({
      ...prev,
      workExperience: Array.isArray(newWorkExperience)
        ? newWorkExperience
        : [initialWorkExperienceEntry],
    }));
  }, []);

  const addTargetJobEntry = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      targetJobInformation: [
        ...prev.targetJobInformation,
        initialTargetJobEntry,
      ],
    }));
  }, []);

  const updateTargetJobEntry = useCallback(
    (index, field, value) => {
      setFormData((prev) => {
        const updatedTargetJobs = [...prev.targetJobInformation];
        if (field.startsWith("generationOptions.")) {
          const [, optionName] = field.split(".");
          updatedTargetJobs[index] = {
            ...updatedTargetJobs[index],
            generationOptions: {
              ...updatedTargetJobs[index].generationOptions,
              [optionName]: value,
            },
          };
        } else {
          updatedTargetJobs[index] = {
            ...updatedTargetJobs[index],
            [field]: value,
          };
        }
        validateFieldAndUpdateErrors(
          `targetJobInformation[${index}]`,
          field,
          value
        );
        return { ...prev, targetJobInformation: updatedTargetJobs };
      });
    },
    [validateFieldAndUpdateErrors]
  );

  const removeTargetJobEntry = useCallback((index) => {
    setFormData((prev) => ({
      ...prev,
      targetJobInformation: prev.targetJobInformation.filter(
        (_, i) => i !== index
      ),
    }));
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[`targetJobInformation[${index}]`];
      return newErrors;
    });
  }, []);

  const setTargetJobInformation = useCallback((newTargetJobs) => {
    setFormData((prev) => ({
      ...prev,
      targetJobInformation: Array.isArray(newTargetJobs)
        ? newTargetJobs
        : [initialTargetJobEntry],
    }));
  }, []);

  const addQualificationEntry = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      additionalQualifications: [
        ...(prev.additionalQualifications || []),
        initialQualificationEntry,
      ],
    }));
  }, []);

  const updateQualificationEntry = useCallback(
    (index, field, value) => {
      setFormData((prev) => {
        const updatedQualifications = [
          ...(prev.additionalQualifications || []),
        ];
        if (field.startsWith("details.")) {
          const [, detailField] = field.split(".");
          updatedQualifications[index] = {
            ...updatedQualifications[index],
            details: {
              ...updatedQualifications[index].details,
              [detailField]: value,
            },
          };
        } else {
          updatedQualifications[index] = {
            ...updatedQualifications[index],
            [field]: value,
          };
        }
        validateFieldAndUpdateErrors(
          `additionalQualifications[${index}]`,
          field,
          value
        );
        return { ...prev, additionalQualifications: updatedQualifications };
      });
    },
    [validateFieldAndUpdateErrors]
  );

  const removeQualificationEntry = useCallback((index) => {
    setFormData((prev) => ({
      ...prev,
      additionalQualifications: (prev.additionalQualifications || []).filter(
        (_, i) => i !== index
      ),
    }));
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[`additionalQualifications[${index}]`];
      return newErrors;
    });
  }, []);

  const toggleNoWorkExperience = useCallback((value) => {
    setFormData((prev) => ({
      ...prev,
      noWorkExperience: value,
      workExperience: value ? [] : [initialWorkExperienceEntry],
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      // Your submission logic here
      // logging
      // console.log("Form submitted successfully", formData);
      // Handle successful submission
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        submit: error.message,
      }));
    }
  }, [formData]);

  return (
    <FormContext.Provider
      value={{
        formData,
        errors,
        updateFormData,
        addEducationEntry,
        updateEducationEntry,
        removeEducationEntry,
        setEducation,
        addWorkExperienceEntry,
        updateWorkExperienceEntry,
        removeWorkExperienceEntry,
        setWorkExperience,
        addTargetJobEntry,
        updateTargetJobEntry,
        removeTargetJobEntry,
        setTargetJobInformation,
        addQualificationEntry,
        updateQualificationEntry,
        removeQualificationEntry,
        toggleNoWorkExperience,
        handleSubmit,
        validateFieldAndUpdateErrors,
        populateFormData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
