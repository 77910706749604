export const generateApplicationMaterials = async (formData, targetJobs) => {
  const apiCalls = targetJobs.map(async (job, index) => {
    const payload = {
      ...formData,
      targetJobInformation: [job],
    };

    try {
      const response = await fetch(
        "https://us-central1-resumerevivalapp.cloudfunctions.net/process_application",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the raw response text
      const rawResponse = await response.text();

      // logging
      // console.log(`Raw response for job ${index + 1}:`);
      // console.log(rawResponse);

      // Parse the response as JSON and return it
      return JSON.parse(rawResponse);
    } catch (error) {
      console.error(`Error for job ${index + 1}:`, error);
      throw error;
    }
  });

  return Promise.all(apiCalls);
};
