// DashboardSection.js
import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import Alert from "@material-ui/lab/Alert";
import LinkMui from "@material-ui/core/Link";
import { EmojiObjects, AccountCircle, HelpOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: theme.spacing(2),
  },
  primaryButton: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1.5, 4),
    textTransform: "none",
    fontWeight: "bold",
    width: "100%",
  },
  secondaryButton: {
    padding: theme.spacing(1.5, 4),
    textTransform: "none",
    width: "100%",
  },
  aboutSection: {
    color: theme.palette.text.primary,
    "& a": {
      textDecoration: "none",
    },
    "& .MuiButton-root": {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  linkButton: {
    padding: theme.spacing(1, 2),
    minWidth: "auto",
    textTransform: "none",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  quickLinksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: theme.spacing(2),
  },
  welcomeMessage: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const savedData = localStorage.getItem("formData");
      const parsedData = savedData ? JSON.parse(savedData) : null;
      setFormData(parsedData);
    } catch (err) {
      console.error("Error retrieving form data:", err);
      setError("Failed to load your saved data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleContinueEditing = () => {
    router.push("/pro");
  };

  const handleStartNew = () => {
    try {
      localStorage.removeItem("formData");
      setFormData(null);
      router.push("/pro");
    } catch (err) {
      console.error("Error starting a new resume:", err);
      setError("Failed to start a new resume.");
    }
  };

  return (
    <Section {...props}>
      <Container>
        <SectionHeader
          title="Dashboard"
          subtitle=""
          size={4}
          textAlign="center"
        />
        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {/* Error Alert */}
        {error && (
          <Box mb={3}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {/* Loading Indicator */}
        {loading ? (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            {/* Main Content Area */}
            <Grid item xs={12} md={7}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classes.welcomeMessage}>
                  <Avatar className={classes.avatar}>
                    <AccountCircle />
                  </Avatar>
                  <Typography variant="h5">
                    Welcome back
                    {formData?.personalInformation?.firstName
                      ? `, ${formData.personalInformation.firstName}`
                      : auth.user && `, ${auth.user.email}`}
                    !
                  </Typography>
                </div>

                <Typography variant="body1" gutterBottom>
                  We're excited to help you craft the perfect resume and cover
                  letter tailored to your dream job.
                </Typography>

                {formData ? (
                  <>
                    <Typography variant="body1" gutterBottom>
                      You have an application in progress.
                    </Typography>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryButton}
                        onClick={handleContinueEditing}
                      >
                        Continue Editing
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" gutterBottom>
                      You haven't started creating a resume yet.
                    </Typography>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryButton}
                        onClick={handleStartNew}
                      >
                        Start New Resume
                      </Button>
                    </div>
                  </>
                )}

                {/* Quick Links */}
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>
                    Quick Links
                  </Typography>
                  <div className={classes.quickLinksContainer}>
                    {/* <Button
                      component={Link}
                      to="/pricing"
                      variant="contained"
                      color="secondary"
                      className={classes.primaryButton}
                    >
                      Upgrade Plan
                    </Button> */}
                    <Button
                      component={Link}
                      to="/settings/general"
                      variant="outlined"
                      className={classes.secondaryButton}
                    >
                      Account Settings
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Grid>

            {/* Sidebar Content */}
            <Grid item xs={12} md={5}>
              <Card elevation={3}>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.aboutSection}>
                    <Typography variant="h6" paragraph>
                      <EmojiObjects className={classes.icon} />
                      <strong>About Resume Revival</strong>
                    </Typography>
                    <Typography paragraph>
                      Resume Revival is your personal career assistant, helping
                      you create professional, ATS-optimized resumes and cover
                      letters tailored to your target jobs. Our platform
                      leverages advanced AI to highlight your strengths and
                      match your skills with job requirements.
                    </Typography>
                    <Typography paragraph>
                      Whether you're starting fresh or looking to improve your
                      existing resume, we're here to support you every step of
                      the way.
                    </Typography>
                    <Typography variant="h6" paragraph>
                      <HelpOutline className={classes.icon} />
                      <strong>Need Help?</strong>
                    </Typography>
                    <div>
                      <Button
                        component={Link}
                        to="/contact"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.linkButton}
                      >
                        Help Center
                      </Button>
                      <Button
                        component={Link}
                        to="/contact"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.linkButton}
                      >
                        Contact Us
                      </Button>
                    </div>

                    {/* Dashboard Essentials */}
                    <Box mt={4}>
                      <Typography variant="h6" paragraph>
                        <strong>Dashboard Essentials</strong>
                      </Typography>
                      <Typography component="div">
                        <div>
                          You are signed in as{" "}
                          <strong>{auth.user.email}</strong>.
                        </div>

                        {/* {auth.user.stripeSubscriptionId && (
                          <div>
                            Subscribed to the{" "}
                            <strong>{auth.user.planId} plan</strong>.
                          </div>
                        )} */}

                        <div>
                          You can change your account info
                          {auth.user.stripeSubscriptionId &&
                            " and plan"} in{" "}
                          <LinkMui component={Link} to="/settings/general">
                            <strong>settings</strong>
                          </LinkMui>
                          .
                        </div>

                        {/* {!auth.user.stripeSubscriptionId && (
                          <div>
                            You can sign up for a plan on our{" "}
                            <LinkMui component={Link} to="/pricing">
                              <strong>pricing</strong>
                            </LinkMui>{" "}
                            page.
                          </div>
                        )} */}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Section>
  );
}

export default DashboardSection;
