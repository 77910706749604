import React, { useState, useEffect, Suspense, lazy } from "react";
import Meta from "./../components/Meta";
import { useDarkMode } from "../util/theme";
import { Fade } from "react-awesome-reveal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

// Lazy load components for better performance
const HeroSection = lazy(() => import("./../components/HeroSection"));
const PoweredBy = lazy(() => import("../components/PoweredBy"));
const FeaturesSection = lazy(() => import("./../components/FeaturesSection"));
const TestimonialsSection = lazy(() =>
  import("../components/TestimonialsSection")
);
const NewsletterSection = lazy(() => import("../components/NewsletterSection"));

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  scrollTopButton: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    width: 70,
    height: 70,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
    zIndex: 1500,
    pointerEvents: "none",
    "&.visible": {
      opacity: 1,
      pointerEvents: "auto",
    },
    [theme.breakpoints.down(1500)]: {
      bottom: theme.spacing(12),
    },
    [theme.breakpoints.down(880)]: {
      bottom: theme.spacing(14),
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonIcon: {
    fontSize: "2.5rem",
  },
}));

function IndexPage(props) {
  const classes = useStyles();
  const { value: isDarkMode } = useDarkMode();
  const isDesktop = useMediaQuery("(min-width:960px)");

  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  // Handle scroll to show/hide the "Back to Top" button
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top handler
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Meta
        title="AI-Powered Job Applications | Accelerate Your Job Search"
        description="Land your dream job faster with AI-powered applications. Apply to 10x more jobs in record time with personalized resumes, cover letters, and application materials."
        keywords="AI job applications, resume builder, cover letter generator, job search acceleration"
        // Add more meta tags as needed
      />
      <main>
        <Suspense fallback={<div>Loading...</div>}>
          <Fade triggerOnce={false}>
            <HeroSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title={
                <>
                  Land Your Dream Job Faster with
                  <br />
                  AI-Powered Applications
                </>
              }
              subtitle="Apply to 10x more jobs in record time. Our AI crafts personalized resumes, cover letters, and application materials for each position. Stand out from the crowd, save countless hours, and skyrocket your chances of success."
              buttonText="Accelerate My Job Search"
              buttonColor="primary"
              buttonPath="/pro"
              showFlameAnimation={isDesktop}
            />
          </Fade>
          <Fade triggerOnce={false}>
            <PoweredBy
              bgColor="light"
              size="normal"
              bgImage=""
              bgImageOpacity={1}
              title="Powered by Cutting-Edge AI Technology"
              subtitle="Harness the power of the world's most advanced AI models to create exceptional resumes and cover letters that stand out"
            />
          </Fade>
          <Fade triggerOnce={false}>
            <FeaturesSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Features"
              subtitle="All the tools you need to accelerate your job search"
              buttonText="Boost My Job Applications"
              buttonColor="primary"
              buttonPath="/pro"
            />
          </Fade>
          <Fade triggerOnce={false}>
            <TestimonialsSection
              bgColor="light"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Here's what people are saying"
              subtitle=""
            />
          </Fade>
          <Fade triggerOnce={false}>
            <NewsletterSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Stay in the know"
              subtitle="Receive our latest articles and feature updates"
              buttonText="Subscribe"
              buttonColor="primary"
              inputPlaceholder="Enter your email"
              subscribedMessage="You are now subscribed!"
            />
          </Fade>
        </Suspense>
      </main>
      {/* "Back to Top" Button */}
      <button
        onClick={scrollToTop}
        className={`${classes.scrollTopButton} ${
          showScrollTopButton ? "visible" : ""
        }`}
        aria-label="Back to Top"
      >
        <ArrowUpwardIcon className={classes.buttonIcon} />
      </button>
    </>
  );
}

export default React.memo(IndexPage);
