// src/components/FormSection4_AdditionalQualifications.js

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Paper,
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  CircularProgress,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormField } from "./FormComponents";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Work as WorkIcon,
  Security as SecurityIcon,
  School as SchoolIcon,
  EmojiObjects as EmojiObjectsIcon,
  Search as SearchIcon,
  Group as GroupIcon,
  MenuBook as MenuBookIcon,
  Stars as MilitaryIcon,
  Create as CreateIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { FormContext } from "./FormContext";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  formPaper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formInnerContent: {
    padding: theme.spacing(3),
  },
  qualificationsHeader: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  qualificationCard: {
    marginBottom: theme.spacing(2),
  },
  qualificationTypeGrid: {
    marginBottom: theme.spacing(3),
  },
  qualificationTypeCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  // Style for the Alert to align with theme
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  // New styles for QualificationForm
  qualificationFormContainer: {
    padding: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

// Static data
const qualificationTypes = [
  {
    type: "Military Service",
    description: "Add details about your military service experience.",
    icon: MilitaryIcon,
  },
  {
    type: "Work Authorization",
    description: "Provide information about your work authorization status.",
    icon: WorkIcon,
  },
  {
    type: "Security Clearance",
    description: "Specify your security clearance level.",
    icon: SecurityIcon,
  },
  {
    type: "Personal Project",
    description:
      "Share information about your personal initiatives or passion projects.",
    icon: CreateIcon,
  },
  {
    type: "Certification",
    description:
      "Add details about your professional licenses, certifications, or other credentials.",
    icon: SchoolIcon,
  },
  {
    type: "Patent",
    description: "Provide information about patents you hold.",
    icon: EmojiObjectsIcon,
  },
  {
    type: "Research Experience",
    description: "Describe your research experience and projects.",
    icon: SearchIcon,
  },
  {
    type: "Volunteering",
    description: "Share details about your community service activities.",
    icon: GroupIcon,
  },
  {
    type: "Professional Development",
    description:
      "Workshops, seminars, courses, or any other professional development activities.",
    icon: MenuBookIcon,
  },
];

const fieldsByType = {
  "Military Service": [
    {
      name: "branch",
      label: "Branch of Service",
      required: true,
      select: true,
      options: [
        "Army",
        "Navy",
        "Air Force",
        "Marine Corps",
        "Coast Guard",
        "Space Force",
      ],
      icon: MilitaryIcon,
      tooltip: "Select the branch of military service you served in",
    },
    {
      name: "rank",
      label: "Rank",
      required: true,
      icon: MilitaryIcon,
      tooltip: "Enter your highest achieved rank",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      icon: MilitaryIcon,
      tooltip: "Enter the date you started your military service",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
      icon: MilitaryIcon,
      tooltip: "Enter the date you ended your military service",
    },
    {
      name: "specialTraining",
      label: "Special Training or Skills",
      multiline: true,
      rows: 4,
      icon: MilitaryIcon,
      tooltip:
        "Describe any special training or skills acquired during your service",
    },
  ],
  "Work Authorization": [
    {
      name: "status",
      label: "Work Authorization Status",
      required: true,
      select: true,
      options: ["US Citizen", "Permanent Resident", "Work Visa", "Other"],
      icon: WorkIcon,
      tooltip: "Select your current work authorization status",
    },
  ],
  "Security Clearance": [
    {
      name: "level",
      label: "Security Clearance Level",
      required: true,
      select: true,
      options: ["Confidential", "Secret", "Top Secret", "Other"],
      icon: SecurityIcon,
      tooltip: "Select your highest level of security clearance",
    },
  ],
  "Personal Project": [
    {
      name: "projectName",
      label: "Project Name",
      required: true,
      icon: CreateIcon,
      tooltip: "Enter the name of your personal project",
    },
    {
      name: "description",
      label: "Project Description",
      multiline: true,
      rows: 4,
      required: true,
      icon: CreateIcon,
      tooltip: "Provide a brief description of your project and its goals",
    },
    {
      name: "skills",
      label: "Skills Utilized",
      required: true,
      icon: CreateIcon,
      tooltip: "List the main skills or techniques you used in this project",
    },
    {
      name: "outcome",
      label: "Project Outcome",
      multiline: true,
      rows: 2,
      icon: CreateIcon,
      tooltip: "Describe the results or impact of your project",
    },
    {
      name: "projectUrl",
      label: "Project URL",
      icon: CreateIcon,
      tooltip: "Enter a URL where your project can be viewed (if applicable)",
    },
  ],
  Certification: [
    {
      name: "credentialType",
      label: "Credential Type",
      required: true,
      select: true,
      options: ["License", "Certification", "Other"],
      icon: SchoolIcon,
      tooltip: "Select the type of professional credential",
    },
    {
      name: "name",
      label: "Credential Name",
      required: true,
      icon: SchoolIcon,
      tooltip: "Enter the name of your professional license or certification",
    },
    {
      name: "issuer",
      label: "Issuing Organization",
      required: true,
      icon: SchoolIcon,
      tooltip: "Enter the name of the organization that issued the credential",
    },
    {
      name: "dateObtained",
      label: "Date Obtained",
      type: "date",
      required: true,
      icon: SchoolIcon,
      tooltip: "Enter the date you obtained this credential",
    },
    {
      name: "expirationDate",
      label: "Expiration Date",
      type: "date",
      icon: SchoolIcon,
      tooltip: "Enter the expiration date of this credential, if applicable",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      rows: 3,
      icon: SchoolIcon,
      tooltip:
        "Provide a brief description of this credential and its relevance to your field",
    },
  ],
  Patent: [
    {
      name: "patentNumber",
      label: "Patent Number",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the patent number",
    },
    {
      name: "patentTitle",
      label: "Patent Title",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the title of the patent",
    },
    {
      name: "dateFiled",
      label: "Date Filed",
      type: "date",
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Enter the date the patent was filed",
    },
    {
      name: "dateGranted",
      label: "Date Granted",
      type: "date",
      icon: EmojiObjectsIcon,
      tooltip: "Enter the date the patent was granted",
    },
    {
      name: "description",
      label: "Patent Description",
      multiline: true,
      rows: 6,
      required: true,
      icon: EmojiObjectsIcon,
      tooltip: "Describe your patent, including its purpose and impact",
    },
  ],
  "Research Experience": [
    {
      name: "projectTitle",
      label: "Project Title",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the title of the research project",
    },
    {
      name: "role",
      label: "Role",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter your role in the project",
    },
    {
      name: "institution",
      label: "Institution/Organization",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the name of the institution or organization",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the starting date of the research project",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
      icon: SearchIcon,
      tooltip: "Enter the ending date of the research project",
    },
    {
      name: "description",
      label: "Project Description",
      multiline: true,
      rows: 4,
      required: true,
      icon: SearchIcon,
      tooltip: "Provide a detailed description of the research project",
    },
    {
      name: "outcomes",
      label: "Outcomes and Findings",
      multiline: true,
      rows: 3,
      icon: SearchIcon,
      tooltip: "Describe the outcomes or findings of the research project",
    },
  ],
  Volunteering: [
    {
      name: "organization",
      label: "Organization",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the name of the organization you volunteered with",
    },
    {
      name: "role",
      label: "Role",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter your role or position in the organization",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the date you started volunteering",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
      icon: GroupIcon,
      tooltip: "Enter the date you ended volunteering",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      rows: 4,
      icon: GroupIcon,
      tooltip: "Describe your community service activities and achievements",
    },
  ],
  "Professional Development": [
    {
      name: "name",
      label: "Activity Name",
      required: true,
      icon: MenuBookIcon,
      tooltip: "Enter the name of the professional development activity",
    },
    {
      name: "type",
      label: "Activity Type",
      required: true,
      select: true,
      options: ["Workshop", "Seminar", "Course", "Conference", "Other"],
      icon: MenuBookIcon,
      tooltip: "Select the type of professional development activity",
    },
    {
      name: "date",
      label: "Date",
      type: "date",
      required: true,
      icon: MenuBookIcon,
      tooltip: "Enter the date of the activity",
    },
    {
      name: "description",
      label: "Description",
      multiline: true,
      rows: 4,
      icon: MenuBookIcon,
      tooltip: "Provide a brief description of the activity",
    },
    {
      name: "location",
      label: "Location",
      icon: MenuBookIcon,
      tooltip: "Enter the location of the activity",
    },
    {
      name: "organizer",
      label: "Organizer",
      icon: MenuBookIcon,
      tooltip: "Enter the name of the organizing body",
    },
  ],
};

// QualificationForm Component
const QualificationForm = ({
  qualificationType,
  initialValues,
  onSave,
  onCancel,
}) => {
  const classes = useStyles();
  const [details, setDetails] = useState(initialValues || {});

  const fields = fieldsByType[qualificationType] || [];

  const handleChange = (name, value) => {
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave({ qualificationType, details });
  };

  return (
    <Box className={classes.qualificationFormContainer}>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12} sm={6} key={field.name}>
            <FormField
              {...field}
              value={details[field.name] || ""}
              onChange={(name, value) => handleChange(name, value)}
              fullWidth
              className={classes.formField}
            >
              {field.select &&
                field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </FormField>
          </Grid>
        ))}
      </Grid>
      <Box className={classes.formButtons}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

// QualificationCard Component
const QualificationCard = ({ qualification, onEdit, onDelete }) => {
  const classes = useStyles();
  const { qualificationType, details } = qualification;

  const fields = fieldsByType[qualificationType] || [];

  return (
    <Card className={classes.qualificationCard}>
      <CardContent>
        <Typography variant="h6">{qualificationType}</Typography>
        {details &&
          fields.map((field) => {
            const value = details[field.name];
            if (value) {
              return (
                <Typography key={field.name}>
                  <strong>{field.label}:</strong> {value}
                </Typography>
              );
            } else {
              return null;
            }
          })}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Tooltip title="Edit">
          <IconButton
            onClick={onEdit}
            size="small"
            aria-label="Edit Qualification"
          >
            <EditIcon aria-hidden="true" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={onDelete}
            size="small"
            aria-label="Delete Qualification"
          >
            <DeleteIcon aria-hidden="true" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

// AdditionalQualifications Component
export const AdditionalQualifications = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingQualification, setEditingQualification] = useState(null);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [loading, setLoading] = useState(false);

  const auth = useAuth(); // Get the authenticated user

  // Ref to store previous form data
  const prevFormDataRef = useRef();

  // Debounced auto-save function to prevent excessive writes
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    []
  );

  // Save data to localStorage and Firestore
  const saveData = async (data) => {
    // Save to localStorage
    localStorage.setItem("additionalQualifications", JSON.stringify(data));

    // If user is authenticated, save to Firestore
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      try {
        // Use setDoc with merge: true
        await setDoc(
          userDocRef,
          { additionalQualifications: data },
          { merge: true }
        );
      } catch (error) {
        console.error("Error saving data:", error);
        throw error; // Re-throw to handle in debouncedAutoSave
      }
    }
  };

  // Load data from localStorage or Firestore on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("additionalQualifications");
        if (localData) {
          const parsedData = JSON.parse(localData);
          updateFormData("additionalQualifications", parsedData);
        } else if (auth.user) {
          // Fetch from Firestore
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data().additionalQualifications;
            if (data) {
              updateFormData("additionalQualifications", data);
              // Optionally, save to localStorage
              localStorage.setItem(
                "additionalQualifications",
                JSON.stringify(data)
              );
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  // Auto-save whenever additionalQualifications data changes
  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.additionalQualifications;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.additionalQualifications, debouncedAutoSave, loading]);

  const handleAddQualification = (qualificationType) => {
    setEditingQualification({ qualificationType, details: {} });
    setDialogOpen(true);
  };

  const handleEditQualification = (qualification, index) => {
    setEditingQualification({ ...qualification, index });
    setDialogOpen(true);
  };

  const handleSaveQualification = (qualification) => {
    if (editingQualification.index !== undefined) {
      const updatedQualifications = [...formData.additionalQualifications];
      updatedQualifications[editingQualification.index] = {
        qualificationType: qualification.qualificationType,
        details: qualification.details,
      };
      updateFormData("additionalQualifications", updatedQualifications);
    } else {
      const updatedQualifications = [
        ...(formData.additionalQualifications || []),
        {
          qualificationType: qualification.qualificationType,
          details: qualification.details,
        },
      ];
      updateFormData("additionalQualifications", updatedQualifications);
    }
    setDialogOpen(false);
    setEditingQualification(null);
  };

  const handleDeleteQualification = (index) => {
    const updatedQualifications = formData.additionalQualifications.filter(
      (_, i) => i !== index
    );
    updateFormData("additionalQualifications", updatedQualifications);
  };

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <div className={classes.formInnerContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Typography variant="h6" className={classes.qualificationsHeader}>
              Additional Qualifications:
            </Typography>
            <Grid
              container
              spacing={3}
              className={classes.qualificationTypeGrid}
            >
              {qualificationTypes.map((qualType) => (
                <Grid item xs={12} sm={6} md={4} key={qualType.type}>
                  <Card className={classes.qualificationTypeCard}>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {React.createElement(qualType.icon, {
                            style: { marginRight: "8px" },
                            "aria-hidden": "true",
                          })}
                          {qualType.type}
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        {qualType.description}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => handleAddQualification(qualType.type)}
                        startIcon={<AddIcon aria-hidden="true" />}
                      >
                        Add {qualType.type}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {(formData.additionalQualifications || []).map(
              (qualification, index) => (
                <QualificationCard
                  key={index}
                  qualification={qualification}
                  onEdit={() => handleEditQualification(qualification, index)}
                  onDelete={() => handleDeleteQualification(index)}
                />
              )
            )}
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              maxWidth="md"
              fullWidth
              aria-labelledby="qualification-dialog-title"
            >
              <DialogTitle
                id="qualification-dialog-title"
                className={classes.dialogTitle}
              >
                {editingQualification?.index !== undefined ? "Edit" : "Add"}{" "}
                {editingQualification?.qualificationType}
              </DialogTitle>
              <DialogContent dividers>
                {editingQualification && (
                  <QualificationForm
                    qualificationType={editingQualification.qualificationType}
                    initialValues={editingQualification.details}
                    onSave={handleSaveQualification}
                    onCancel={() => setDialogOpen(false)}
                  />
                )}
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert} // Apply custom styling
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdditionalQualifications;
