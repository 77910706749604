// src/components/JobDetailsForm.js

import React, { useState, useContext } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Button,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { FormContext } from "./FormContext";
import { PersonalInformation } from "./FormSection1_PersonalInformation.js";
import { WorkExperience } from "./FormSection3_WorkExperience.js";
import { Education } from "./FormSection2_Education.js";
// import { TargetJobInformation } from "./FormSection5_TargetJobInformation.js";
import TargetJobInformation from "./FormSection5_TargetJobInformation";
import { AdditionalQualifications } from "./FormSection4_AdditionalQualifications.js";
import UploadResumeButton from "./UploadResumeButton";
import FormSectionHeader from "./FormSectionHeader";

import { db } from "../util/firebase";
import { doc, setDoc } from "firebase/firestore";

// ErrorBoundary Component to catch errors in child components
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details if needed
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Alert severity="error">Something went wrong.</Alert>;
    }

    return this.props.children;
  }
}

// Define custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(4),
    position: "relative", // To position the UploadResumeButton absolutely on desktop
  },
  formContent: {
    position: "relative",
  },
  uploadButtonContainer: {
    position: "absolute",
    right: -100, // Adjust as needed to position the button outside the form
    top: 120, // Adjust as needed for vertical alignment
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
  },
  uploadButtonContainerMobile: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  alertBox: {
    marginTop: theme.spacing(2),
  },
  navigationButtonsTop: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      // Removed absolute positioning
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  navigationButtonsBottom: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  stepper: {
    padding: theme.spacing(3, 0),
    backgroundColor: "transparent",
  },
}));

// Define the form steps with corresponding components
const formSteps = [
  { title: "Personal Information", component: PersonalInformation },
  { title: "Education History", component: Education },
  { title: "Work Experience", component: WorkExperience },
  { title: "Additional Qualifications", component: AdditionalQualifications },
  { title: "Target Job Information", component: TargetJobInformation },
];

function JobDetailsForm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { formData } = useContext(FormContext); // Access formData from context

  const [currentStep, setCurrentStep] = useState(0); // 0-based index
  const totalSteps = formSteps.length;

  // Handle navigating back in the form
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // Handle navigating forward in the form and saving data
  const handleNext = async () => {
    if (currentStep === 0) {
      // Save Personal Information to Firestore if it has changed from localStorage
      try {
        const localData = localStorage.getItem("personalInformation");
        const currentData = formData.personalInformation;
        let dataChanged = true;
        if (localData) {
          const parsedLocalData = JSON.parse(localData);
          dataChanged =
            JSON.stringify(parsedLocalData) !== JSON.stringify(currentData);
        }

        if (dataChanged && auth.user) {
          // Save to Firestore
          const userDocRef = doc(db, "users", auth.user.uid);
          await setDoc(
            userDocRef,
            { personalInformation: currentData },
            { merge: true }
          );
          // console.log("Personal Information saved to Firestore.");
          setSnackbarOpen(true); // Show success message
        }
      } catch (error) {
        console.error("Error saving Personal Information to Firestore:", error);
        setFormAlert({
          type: "error",
          message: "Error saving data to Firestore.",
        });
      }
    }

    if (currentStep < totalSteps - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  // Render the current form section
  const renderSection = () => {
    const StepComponent = formSteps[currentStep].component;
    return (
      <ErrorBoundary>
        <StepComponent />
      </ErrorBoundary>
    );
  };

  // Calculate progress for potential use in progress bars or indicators
  const progress = ((currentStep + 1) / totalSteps) * 100;

  return (
    <>
      {/* Form container */}
      <Box className={classes.formContainer}>
        {/* On desktop, render Upload Resume Button */}
        {!isMobile && (
          <Box className={classes.uploadButtonContainer}>
            <Tooltip title="Upload your resume for AI analysis">
              {/* Wrap UploadResumeButton with a span to fix Tooltip ref warning */}
              <span>
                <UploadResumeButton isMobile={isMobile} />
              </span>
            </Tooltip>
          </Box>
        )}

        {/* On desktop, render Stepper with labels */}
        {!isMobile && (
          <>
            {/* Stepper for form navigation */}
            <Stepper
              activeStep={currentStep}
              alternativeLabel
              className={classes.stepper}
            >
              {formSteps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* Navigation Buttons at the top */}
            <Box className={classes.navigationButtonsTop}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBack}
                disabled={currentStep === 0}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={currentStep === totalSteps - 1}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {/* On mobile, render FormSectionHeader with handlers */}
        {isMobile && (
          <>
            {/* Form Section Header with progress bar and navigation icons */}
            <FormSectionHeader
              title={formSteps[currentStep].title}
              progress={progress}
              isMobile={isMobile}
              onBack={handleBack}
              onNext={handleNext}
              isLastStep={currentStep === totalSteps - 1}
            />
          </>
        )}

        {/* Render the current form section */}
        <Box className={classes.formContent}>{renderSection()}</Box>

        {/* Form Alert */}
        {formAlert && (
          <Box className={classes.alertBox}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

        {/* Navigation Buttons at the bottom */}
        <Box className={classes.navigationButtonsBottom}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
            disabled={currentStep === 0}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={currentStep === totalSteps - 1}
          >
            Next
          </Button>
        </Box>
      </Box>

      {/* On mobile, render Upload Resume Button after form container */}
      {isMobile && (
        <Box className={classes.uploadButtonContainerMobile}>
          <Tooltip title="Upload your resume for AI analysis">
            {/* Wrap UploadResumeButton with a span to fix Tooltip ref warning */}
            <span>
              <UploadResumeButton isMobile={isMobile} />
            </span>
          </Tooltip>
        </Box>
      )}

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message="Personal Information saved."
      />
    </>
  );
}

export default JobDetailsForm;
