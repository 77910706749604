// src/pages/App.jsx
import React from "react";
import CookieConsent from "react-cookie-consent";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import ProPage from "./pro";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import logo_image from "./../images/logo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    flex: 1, // Expands to fill available space
  },
  // Custom styles for CookieConsent
  cookieConsent: {
    position: "fixed", // Fixes the banner at the bottom
    bottom: 0,
    left: 0,
    width: "100%",
    background: theme.palette.primary.main, // Brand primary color
    color: theme.palette.primary.contrastText, // Ensure text is readable
    fontSize: "0.875rem", // Slightly smaller font size
    padding: "10px 20px", // Padding around the content
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 0, // Start hidden for fade-in
    animation: "$fadeIn 0.5s forwards", // Apply fade-in animation
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: "20px", // Additional bottom padding for larger screens
    },
    zIndex: 1000, // Ensure it stays above other elements
  },
  hide: {
    animation: "$fadeOut 0.5s forwards", // Apply fade-out animation
  },
  cookieText: {
    margin: 0,
    padding: 0,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
    "& a": {
      color: theme.palette.secondary.light, // Secondary color for links
      textDecoration: "underline",
    },
  },
  cookieButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  cookieButton: {
    background: theme.palette.secondary.main, // Brand secondary color
    color: theme.palette.secondary.contrastText,
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px", // Consistent padding
    cursor: "pointer",
    fontSize: "0.875rem",
    marginTop: "10px", // Space above buttons on small screens
    transition: "background 0.3s ease",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0, // Remove top margin on larger screens
      marginLeft: "10px", // Space between buttons on larger screens
    },
    "&:hover": {
      background: theme.palette.secondary.dark, // Darker shade on hover
    },
    "@media (max-width:1500px)": {
      padding: "8px 16px", // Consistent padding below 1500px
      marginLeft: "10px", // Sensible margin below 1500px
    },
  },
  // Define keyframes for fade-in
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
    to: {
      opacity: 0.95,
      transform: "translateY(0)",
    },
  },
  // Define keyframes for fade-out
  "@keyframes fadeOut": {
    from: {
      opacity: 0.95,
      transform: "translateY(0)",
    },
    to: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  },
}));

function App(props) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = React.useState(true);
  const [isHiding, setIsHiding] = React.useState(false);

  const handleAccept = () => {
    setIsHiding(true);
    // After fade-out animation, hide the banner
    setTimeout(() => {
      setIsVisible(false);
    }, 500); // 500ms matches the animation duration
  };

  const handleDecline = () => {
    setIsHiding(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  };

  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <div className={classes.appContainer}>
              {/* Navbar at the top */}
              <Navbar
                color="default"
                logo={logo_image}
                logoInverted={logo_image}
              />

              {/* Main content area */}
              <div className={classes.content}>
                <Switch>
                  <Route exact path="/" component={IndexPage} />
                  <Route exact path="/about" component={AboutPage} />
                  <Route exact path="/faq" component={FaqPage} />
                  <Route exact path="/contact" component={ContactPage} />
                  <Route exact path="/pricing" component={PricingPage} />
                  <Route exact path="/dashboard" component={DashboardPage} />
                  <Route exact path="/auth/:type" component={AuthPage} />
                  <Route
                    exact
                    path="/settings/:section"
                    component={SettingsPage}
                  />
                  <Route exact path="/legal/:section" component={LegalPage} />
                  <Route exact path="/pro" component={ProPage} />
                  <Route
                    exact
                    path="/purchase/:plan"
                    component={PurchasePage}
                  />
                  <Route
                    exact
                    path="/firebase-action"
                    component={FirebaseActionPage}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              </div>

              {/* Footer at the bottom */}
              <Footer
                bgColor="light"
                size="normal"
                bgImage=""
                bgImageOpacity={1}
                description="A short description of what you do here"
                copyright={`© ${new Date().getFullYear()} Resume Revival`}
                logo={logo_image}
                logoInverted={logo_image}
                sticky={true}
              />

              {/* Cookie Consent */}
              {isVisible && (
                <CookieConsent
                  location="bottom"
                  buttonText="I Understand"
                  cookieName="myAppCookieConsent"
                  enableDeclineButton
                  declineButtonText="Decline"
                  onAccept={handleAccept}
                  onDecline={handleDecline}
                  containerClasses={`${classes.cookieConsent} ${
                    isHiding ? classes.hide : ""
                  }`}
                  buttonClasses={classes.cookieButton}
                  declineButtonClasses={classes.cookieButton}
                  // Disable default styles to fully control styling
                  disableStyles
                >
                  <span className={classes.cookieText}>
                    We use cookies to enhance your experience, personalize
                    content, and analyze our traffic. By clicking "I
                    Understand", you agree to our use of cookies. Read our{" "}
                    <a href="/legal/privacy-policy">Privacy Policy</a> for more
                    information.
                  </span>
                </CookieConsent>
              )}
            </div>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
