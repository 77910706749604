// GeneratedContentDisplay.js
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Tooltip,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  Paper,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TemplateIcon from "@material-ui/icons/Description";
import WorkIcon from "@material-ui/icons/Work";
import DescriptionIcon from "@material-ui/icons/Description";
import MessageIcon from "@material-ui/icons/Message";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import SchoolIcon from "@material-ui/icons/School";
import EmailIcon from "@material-ui/icons/Email";
import TimelineIcon from "@material-ui/icons/Timeline";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PersonIcon from "@material-ui/icons/Person";
import StarIcon from "@material-ui/icons/Star";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BusinessIcon from "@material-ui/icons/Business";
import ApplicationMaterialSkeleton from "./ApplicationMaterialSkeleton";
import { FormContext } from "./FormContext";
import debounce from "lodash/debounce";
import Carousel from "react-material-ui-carousel";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(6),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    flexGrow: 1,
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  contentField: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      padding: theme.spacing(1),
    },
    "& .MuiInputBase-input": {
      minHeight: "80px",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  jobHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  sectionTitle: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
  },
  boldText: {
    fontWeight: "bold",
  },
  workExperienceItem: {
    marginBottom: theme.spacing(2),
  },
  workExperienceDetails: {
    marginBottom: theme.spacing(1),
  },
  additionalQualification: {
    marginBottom: theme.spacing(2),
  },
  templateButton: {
    marginLeft: theme.spacing(1),
  },
  templatePreview: {
    width: "100%",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    border: "2px solid transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  selectedTemplate: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  templateName: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  downloadText: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
  markdownOutput: {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginBottom: theme.spacing(1),
    },
    "& ul, & ol": {
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    "& pre": {
      backgroundColor: "#f0f0f0",
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      overflowX: "auto",
    },
  },
  accordionSummary: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      margin: 0,
    },
  },
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
}));

const sectionIcons = {
  resume: <WorkIcon />,
  coverLetter: <DescriptionIcon />,
  generate_references: <PersonIcon />,
  generate_recruiter_message: <MessageIcon />,
  generate_linkedin_profile_optimization: <LinkedInIcon />,
  generate_interview_preparation: <SchoolIcon />,
  generate_networking_email_templates: <EmailIcon />,
  generate_career_development_plan: <TimelineIcon />,
  generate_job_application_follow_up_emails: <EmailIcon />,
  generate_salary_negotiation_scripts: <MonetizationOnIcon />,
  generate_professional_bio: <PersonIcon />,
  generate_personal_branding_tips: <StarIcon />,
  generate_skill_gap_analysis: <AssessmentIcon />,
  generate_company_research_reports: <BusinessIcon />,
};

const GeneratedContentDisplay = ({
  generatedContent,
  updateGeneratedContent,
  jobDetails = {},
  isLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { formData } = useContext(FormContext);
  const [expandedPanel, setExpandedPanel] = useState("resume");
  const [coverLetterContent, setCoverLetterContent] = useState("");
  const contentRef = useRef(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (generatedContent?.coverLetter) {
      const content = generatedContent.coverLetter;
      setCoverLetterContent(
        `${content.greeting}\n\n${content.openingParagraph}\n\n${(
          content.bodyParagraphs || []
        ).join("\n\n")}\n\n${content.closingParagraph}\n\n${content.signature}`
      );
    }
  }, [generatedContent]);

  const formatBulletPoints = (responsibilities) => {
    return responsibilities.map((resp) => `• ${resp}`).join("\n");
  };

  const parseBulletPoints = (text) => {
    return text.split("\n").map((line) => line.trim().replace(/^•\s*/, ""));
  };

  const formatDate = (dateString) => {
    if (
      dateString === "9999-01-01" ||
      dateString.toLowerCase() === "present" ||
      dateString.toLowerCase() === "current"
    )
      return "Present";
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    });
  };

  const formatFullResume = (content) => {
    if (!content || typeof content !== "object") return "";

    let formattedResume = "";

    formattedResume += `${content.personalInformation.firstName} ${content.personalInformation.lastName}\n`;
    formattedResume += `${content.personalInformation.email} | ${content.personalInformation.phone}\n`;
    if (content.personalInformation.address) {
      formattedResume += `${content.personalInformation.address}\n`;
    }
    if (content.personalInformation.linkedinUrl) {
      formattedResume += `${content.personalInformation.linkedinUrl}\n`;
    }
    formattedResume += "\n";

    formattedResume += "Professional Summary\n";
    formattedResume += `${content.personalInformation.professionalSummary}\n\n`;

    formattedResume += "Work Experience\n";
    content.workExperience.forEach((job) => {
      formattedResume += `${job.jobTitle} at ${job.companyName}\n`;
      formattedResume += `${formatDate(job.startDate)} - ${formatDate(
        job.endDate
      )}\n`;
      job.responsibilities.forEach((resp) => {
        formattedResume += `• ${resp}\n`;
      });
      formattedResume += "\n";
    });

    formattedResume += "Education\n";
    content.education.forEach((edu) => {
      formattedResume += `${edu.degree} from ${edu.institution}, ${formatDate(
        edu.graduationDate
      )}\n`;
    });
    formattedResume += "\n";

    formattedResume += "Skills\n";
    formattedResume += content.skills.join(", ");
    formattedResume += "\n\n";

    if (
      content.additionalQualifications &&
      content.additionalQualifications.length > 0
    ) {
      formattedResume += "Additional Qualifications\n";
      content.additionalQualifications.forEach((qual) => {
        if (typeof qual === "string") {
          formattedResume += `${qual}\n\n`;
        } else if (qual.qualificationType && qual.details) {
          formattedResume += `${qual.qualificationType}: ${qual.details}\n\n`;
        } else {
          formattedResume += `${JSON.stringify(qual)}\n\n`;
        }
      });
    }

    return formattedResume;
  };

  const formatFullCoverLetter = (content) => {
    if (!content || typeof content !== "object") return "";

    let formattedCoverLetter = "";

    if (content.recipientName) {
      formattedCoverLetter += `${content.recipientName}\n`;
    }
    if (content.recipientTitle) {
      formattedCoverLetter += `${content.recipientTitle}\n`;
    }
    if (content.companyName) {
      formattedCoverLetter += `${content.companyName}\n\n`;
    }

    formattedCoverLetter += `${content.greeting || ""}\n\n`;
    formattedCoverLetter += `${content.openingParagraph || ""}\n\n`;
    formattedCoverLetter += `${(content.bodyParagraphs || []).join(
      "\n\n"
    )}\n\n`;
    formattedCoverLetter += `${content.closingParagraph || ""}\n\n`;
    formattedCoverLetter += `${content.signature || ""}`;

    return formattedCoverLetter;
  };

  const handleCopy = (content, key) => {
    let textToCopy;

    if (key === "resume") {
      textToCopy = formatFullResume(content);
    } else if (key === "coverLetter") {
      textToCopy = formatFullCoverLetter(content);
    } else {
      textToCopy =
        typeof content === "string"
          ? content.replace(/[#*_`]|\n```[\s\S]*?\n```/g, "")
          : JSON.stringify(content, null, 2);
    }

    navigator.clipboard.writeText(textToCopy).then(() => {
      setSnackbarOpen(true);
    });
  };

  const renderResumeSection = (content) => {
    if (!content || typeof content !== "object") {
      return <Typography>Invalid resume data</Typography>;
    }

    return (
      <Box>
        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Contact Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography>{`${content.personalInformation.firstName} ${content.personalInformation.lastName}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{`${content.personalInformation.email} | ${content.personalInformation.phone}`}</Typography>
            </Grid>
            {content.personalInformation.address && (
              <Grid item xs={12}>
                <Typography>{content.personalInformation.address}</Typography>
              </Grid>
            )}
            {content.personalInformation.linkedinUrl && (
              <Grid item xs={12}>
                <Typography>
                  {content.personalInformation.linkedinUrl}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Professional Summary
          </Typography>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            value={content.personalInformation.professionalSummary || ""}
            onChange={(e) => {
              const updatedResume = { ...content };
              updatedResume.personalInformation.professionalSummary =
                e.target.value;
              updateGeneratedContent("resume", updatedResume);
            }}
            className={classes.contentField}
          />
        </Box>

        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Work Experience
          </Typography>
          {content.workExperience.map((job, index) => (
            <Box key={index} className={classes.workExperienceItem}>
              <Box className={classes.workExperienceDetails}>
                <Typography
                  className={classes.boldText}
                >{`${job.jobTitle} at ${job.companyName}`}</Typography>
                <Typography className={classes.boldText}>
                  {`${formatDate(job.startDate)} - ${formatDate(job.endDate)}`}
                </Typography>
              </Box>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                label="Responsibilities"
                value={formatBulletPoints(job.responsibilities)}
                onChange={(e) => {
                  const updatedResume = { ...content };
                  updatedResume.workExperience[index].responsibilities =
                    parseBulletPoints(e.target.value);
                  updateGeneratedContent("resume", updatedResume);
                }}
                className={classes.contentField}
              />
            </Box>
          ))}
        </Box>

        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Education
          </Typography>
          {content.education.map((edu, index) => (
            <Typography key={index}>{`${edu.degree} from ${
              edu.institution
            }, ${formatDate(edu.graduationDate)}`}</Typography>
          ))}
        </Box>

        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Skills
          </Typography>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            value={(content.skills || []).join(", ")}
            onChange={(e) => {
              const updatedResume = { ...content };
              updatedResume.skills = e.target.value
                .split(",")
                .map((skill) => skill.trim());
              updateGeneratedContent("resume", updatedResume);
            }}
            className={classes.contentField}
          />
        </Box>

        {content.additionalQualifications &&
          content.additionalQualifications.length > 0 && (
            <Box className={classes.sectionContainer}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Additional Qualifications
              </Typography>
              {content.additionalQualifications.map((qual, index) => (
                <Box key={index} className={classes.additionalQualification}>
                  {typeof qual === "string" ? (
                    <TextField
                      multiline
                      fullWidth
                      variant="outlined"
                      value={qual}
                      onChange={(e) => {
                        const updatedResume = { ...content };
                        updatedResume.additionalQualifications[index] =
                          e.target.value;
                        updateGeneratedContent("resume", updatedResume);
                      }}
                      className={classes.contentField}
                    />
                  ) : (
                    <>
                      {qual.qualificationType && (
                        <Typography className={classes.boldText}>
                          {qual.qualificationType}
                        </Typography>
                      )}
                      {qual.details && (
                        <TextField
                          multiline
                          fullWidth
                          variant="outlined"
                          value={
                            typeof qual.details === "object"
                              ? JSON.stringify(qual.details, null, 2)
                              : qual.details
                          }
                          onChange={(e) => {
                            const updatedResume = { ...content };
                            updatedResume.additionalQualifications[
                              index
                            ].details = e.target.value;
                            updateGeneratedContent("resume", updatedResume);
                          }}
                          className={classes.contentField}
                        />
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          )}
      </Box>
    );
  };

  const renderCoverLetterSection = (content) => {
    if (!content || typeof content !== "object") {
      return <Typography>Invalid cover letter data</Typography>;
    }

    const today = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const headerInfo = [
      `${formData.personalInformation.firstName} ${formData.personalInformation.lastName}`,
      formData.personalInformation.streetAddress,
      formData.personalInformation.city &&
      formData.personalInformation.state &&
      formData.personalInformation.zipCode
        ? `${formData.personalInformation.city}, ${formData.personalInformation.state} ${formData.personalInformation.zipCode}`
        : null,
      formData.personalInformation.phone,
      formData.personalInformation.email,
      formData.personalInformation.linkedinUrl,
      "",
      today,
      "",
      "",
      "",
      content.recipientName || "Hiring Manager",
      content.companyName,
      jobDetails.jobLocation,
    ]
      .filter(Boolean)
      .join("\n");

    return (
      <Box>
        <Box className={classes.sectionContainer}>
          <Typography
            variant="body2"
            style={{ whiteSpace: "pre-line", marginBottom: "20px" }}
          >
            {headerInfo}
          </Typography>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            value={coverLetterContent}
            onChange={handleCoverLetterChange}
            className={classes.contentField}
          />
        </Box>
      </Box>
    );
  };

  const debouncedUpdateCoverLetter = useCallback(
    debounce((newContent) => {
      const updatedCoverLetter = parseCoverLetterContent(newContent);
      updateGeneratedContent("coverLetter", {
        ...generatedContent.coverLetter,
        ...updatedCoverLetter,
      });
    }, 300),
    [generatedContent.coverLetter]
  );

  const handleCoverLetterChange = (e) => {
    const newContent = e.target.value;
    setCoverLetterContent(newContent);
    debouncedUpdateCoverLetter(newContent);
  };

  const parseCoverLetterContent = (text) => {
    const lines = text.split("\n");
    const nonEmptyLines = lines.filter((line) => line.trim() !== "");
    const signatureIndex = nonEmptyLines.length - 1;
    const closingParagraphIndex = nonEmptyLines.length - 2;
    const greeting = nonEmptyLines[0] || "";
    const openingParagraph = nonEmptyLines[1] || "";
    const bodyParagraphs = nonEmptyLines.slice(2, closingParagraphIndex) || [];
    const closingParagraph = nonEmptyLines[closingParagraphIndex] || "";
    const signature = nonEmptyLines[signatureIndex] || "";

    return {
      greeting,
      openingParagraph,
      bodyParagraphs,
      closingParagraph,
      signature,
    };
  };

  const handleChooseTemplate = (type) => {
    setSelectedTemplateType(type);
    setOpenTemplateDialog(true);
  };

  const handleCloseTemplateDialog = () => {
    setOpenTemplateDialog(false);
  };

  const handleSelectTemplate = (templateId) => {
    setSelectedTemplateId(templateId);
  };

  const handleApplyTemplate = () => {
    console.log("Template ID:", selectedTemplateId);
    console.log(
      "Document Contents:",
      selectedTemplateType === "resume"
        ? formatFullResume(generatedContent.resume)
        : formatFullCoverLetter(generatedContent.coverLetter)
    );
    setOpenTemplateDialog(false);
  };

  const renderTemplateCarousel = () => {
    const templates = [
      { id: "template1", name: "Classic", color: "#f0f0f0" },
      { id: "template2", name: "Modern", color: "#e0e0e0" },
      { id: "template3", name: "Creative", color: "#d0d0d0" },
      { id: "template4", name: "Professional", color: "#c0c0c0" },
      { id: "template5", name: "Elegant", color: "#b0b0b0" },
    ];

    return (
      <Carousel>
        {templates.map((template) => (
          <Box
            key={template.id}
            className={`${classes.templatePreview} ${
              selectedTemplateId === template.id ? classes.selectedTemplate : ""
            }`}
            style={{ backgroundColor: template.color }}
            onClick={() => handleSelectTemplate(template.id)}
          >
            <Typography variant="h6" className={classes.templateName}>
              {template.name}
            </Typography>
            <Typography variant="body1" className={classes.downloadText}>
              Click to download your{" "}
              {selectedTemplateType === "resume" ? "resume" : "cover letter"}{" "}
              with this template
            </Typography>
          </Box>
        ))}
      </Carousel>
    );
  };

  const renderContentSection = (title, content, key) => {
    if (isLoading) {
      return <ApplicationMaterialSkeleton key={key} title={title} />;
    }

    if (!content) return null;

    let displayContent;
    if (key === "resume") {
      displayContent = <>{renderResumeSection(content)}</>;
    } else if (key === "coverLetter") {
      displayContent = <>{renderCoverLetterSection(content)}</>;
    } else {
      displayContent = (
        <ReactMarkdown
          className={classes.markdownOutput}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  style={atomDark}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {typeof content === "string"
            ? content
            : JSON.stringify(content, null, 2)}
        </ReactMarkdown>
      );
    }

    return (
      <Accordion
        expanded={expandedPanel === key}
        onChange={handleAccordionChange(key)}
        key={key}
        style={{ marginBottom: theme.spacing(2) }}
        square
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${key}-content`}
          id={`${key}-header`}
          className={classes.accordionSummary}
        >
          {sectionIcons[key] && <Box mr={1}>{sectionIcons[key]}</Box>}
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: theme.spacing(1) }}>
          <Box width="100%">
            {displayContent}
            <Box className={classes.buttonGroup}>
              <Tooltip title="Copy to clipboard" aria-label="copy to clipboard">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<FileCopyIcon />}
                  onClick={() => handleCopy(content, key)}
                >
                  Copy
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box ref={contentRef} className={classes.root}>
      <Typography variant="h5" className={classes.jobHeader}>
        {jobDetails.jobTitle} at {jobDetails.companyName}
      </Typography>
      {renderContentSection("Resume", generatedContent?.resume, "resume")}

      {renderContentSection(
        "Cover Letter",
        generatedContent?.coverLetter,
        "coverLetter"
      )}

      {renderContentSection(
        "References",
        generatedContent?.generate_references,
        "generate_references"
      )}
      {renderContentSection(
        "Recruiter Message",
        generatedContent?.generate_recruiter_message,
        "generate_recruiter_message"
      )}
      {renderContentSection(
        "LinkedIn Profile Optimization",
        generatedContent?.generate_linkedin_profile_optimization,
        "generate_linkedin_profile_optimization"
      )}
      {renderContentSection(
        "Interview Preparation",
        generatedContent?.generate_interview_preparation,
        "generate_interview_preparation"
      )}
      {renderContentSection(
        "Networking Email Templates",
        generatedContent?.generate_networking_email_templates,
        "generate_networking_email_templates"
      )}
      {renderContentSection(
        "Career Development Plan",
        generatedContent?.generate_career_development_plan,
        "generate_career_development_plan"
      )}
      {renderContentSection(
        "Job Application Follow-up Emails",
        generatedContent?.generate_job_application_follow_up_emails,
        "generate_job_application_follow_up_emails"
      )}
      {renderContentSection(
        "Salary Negotiation Scripts",
        generatedContent?.generate_salary_negotiation_scripts,
        "generate_salary_negotiation_scripts"
      )}
      {renderContentSection(
        "Professional Bio",
        generatedContent?.generate_professional_bio,
        "generate_professional_bio"
      )}
      {renderContentSection(
        "Personal Branding Tips",
        generatedContent?.generate_personal_branding_tips,
        "generate_personal_branding_tips"
      )}
      {renderContentSection(
        "Skill Gap Analysis",
        generatedContent?.generate_skill_gap_analysis,
        "generate_skill_gap_analysis"
      )}
      {renderContentSection(
        "Company Research Reports",
        generatedContent?.generate_company_research_reports,
        "generate_company_research_reports"
      )}
      <Dialog
        open={openTemplateDialog}
        onClose={handleCloseTemplateDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Choose a Template</DialogTitle>
        <DialogContent>{renderTemplateCarousel()}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTemplateDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleApplyTemplate}
            color="primary"
            variant="contained"
            disabled={!selectedTemplateId}
          >
            Apply Template
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          Content copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GeneratedContentDisplay;
